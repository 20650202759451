<template>
    <div>
        <v-sheet
        outlined
        elevation="1"
        >
            <div class="pa-4">
                <h3>{{title}} (<a :href="href" target="_blank" rel="noopener noreferrer">{{href}}</a>)</h3>

                <v-card
                outlined
                elevation="0"
                class="my-2"
                :loading="searching"
                >
                <v-card-title>
                    <v-text-field
                    v-model="tableSearch"
                    append-icon="mdi-filter"
                    label="Filter results"
                    single-line
                    hide-details
                    dense
                    ></v-text-field>
                    <v-spacer class="d-none d-md-inline-block"></v-spacer>
                    <v-spacer class="d-none d-md-inline-block"></v-spacer>
                </v-card-title>
                <v-data-table
                    :headers="tableHeaders"
                    :items="result"
                    :search="tableSearch"
                    :items-per-page="itemsPerPage"
                    dense
                >
                    <template
                        v-slot:[`item.url`]="{ item }"
                    >
                        <a :href="item.url" target="_blank" rel="noopener noreferrer">{{ item.url }}</a>
                    </template>
                </v-data-table>

                <div>
                </div>
                </v-card>
            </div>
        </v-sheet>
    </div>
</template>

<script>
export default {
    data: ()=> ({
        tableSearch: null,
        itemsPerPage: 5
    }),

    props: {
        title: String,
        href: String,
        tableHeaders: Array,
        result: Array,
        searching: Boolean || String
    }
}
</script>