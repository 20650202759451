<template>
  <v-app>
    <v-app-bar
      app
      color="grey lighten-3"
      dense
      light
      elevation="1"
      id="main-bar"
    >
      <v-img class="mb-1" height="40" max-width="78" contain src="@/assets/logo.png"></v-img>
      <v-toolbar-title class="ml-4">
        VULNERABILITY SEARCH
      </v-toolbar-title>
      
      <!-- <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    url: null,
    info: null,
    
  }),
  
};
</script>

<style scoped>
  .v-main{
    background-color:#fcfcfc;
  }
  #main-bar{
    left: 0 !important;
  }
  html{
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }
</style>