<template>
  <div class="home">
    <v-navigation-drawer
      app clipped  mobile-breakpoint="960"
    >
      <div class="pa-2">
        <v-img :src="navImgURL" height="180" width="256"></v-img>
      </div>

      <v-divider></v-divider>

      <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                class="pt-0"
                v-model="search"
                append-outer-icon="mdi-magnify"
                clear-icon="mdi-close-circle"
                clearable
                label="Search"
                type="text"
                @click:append-outer="execSearch"
                @click:clear="clearSearch"
                @keydown.enter="execSearch"
                @keydown.esc="clearSearch"
              ></v-text-field>
              <v-text-field
                v-model="searchedTerm"
                label="Search string"
                filled
                disabled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
      </v-container>

      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pa-2 text-center">
          info@wikisecurity.net
        </div>
      </template>
    </v-navigation-drawer>
    <v-container
      md
    >
      <v-row>
        <v-col
         col="12"
        >
          <v-sheet
            outlined
            class="d-md-none mb-2"
            elevation="1"
          >
            <div class="pa-4">
              <v-text-field
                class="pt-0"
                v-model="search"
                append-outer-icon="mdi-magnify"
                clear-icon="mdi-close-circle"
                clearable
                label="Search"
                type="text"
                @click:append-outer="execSearch"
                @click:clear="clearSearch"
                @keydown.enter="execSearch"
                @keydown.esc="clearSearch"
              ></v-text-field>
            </div>
          </v-sheet>

          <vuln-table 
            title="NVD-CVE Database"
            href="https://nvd.nist.gov/"
            :tableHeaders="tableHeaders.nvd" 
            :searching="searching.nvd"
            :result="result.nvd"
          ></vuln-table>

          <vuln-table 
            class="my-3"
            title="Exploit Database"
            href="https://www.exploit-db.com/"
            :tableHeaders="tableHeaders.exploit" 
            :searching="searching.exploit"
            :result="result.exploit"
          ></vuln-table>

          <vuln-table 
            title="Packet Storm"
            href="https://packetstormsecurity.com/)"
            :tableHeaders="tableHeaders.packetStorm" 
            :searching="searching.packetStorm"
            :result="result.packetStorm"
          ></vuln-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import VulnTable from "../components/home/VulnTable.vue";

export default {
  name: 'Home',
  components: {
    VulnTable
  }, 
  data: () => ({
    result: {
      exploit: [],
      nvd: [],
      packetStorm: []
    },
    search: null,
    searchedTerm: null,
    searching: {
      exploit: false,
      nvd: false,
      packetStorm: false
    },
    data: null,
    tableHeaders: {
      exploit: [
        { text: 'Date', value: 'date' },
        { text: 'Title', value: 'title' },
        { text: 'Author', value: 'author' },
        { text: 'Platform', value: 'platform' },
        { text: 'Type', value: 'type' },
        { text: 'URL', value: 'url' }
      ],
      nvd: [
        { text: 'Code', value: 'code' },
        { text: 'Description', value: 'description'},
        { text: 'URL', value: 'url' }
      ],
      packetStorm: [
        { text: 'Title', value: 'title' },
        { text: 'URL', value: 'url'}
      ]
    }
  }),


  computed: {
    navImgURL: function () {
      const ran = Math.floor(Math.random() * 92) + 1;
      return `https://wiki-lab.wikisecurity.net/images/nasa%20(${ran}).jpg`
    }
  },
  methods: {
    clearSearch () {
      this.search = ''
    },
    execSearch () {
      this.searching.exploit = true;
      this.searching.nvd = true;
      this.searching.packetStorm = true;
      this.searchedTerm = this.search;
      axios.post('https://vul-api.wikisecurity.net/vuldb/exploit/search', {keyword: this.search})
      .then(response => {
        console.log(response.data);
        this.result.exploit = response.data;
      })
      .catch(error => {
        console.log(error);
        this.searching.exploit = 'red';
      })
      .finally( ()=> {
        this.searching.exploit = false;
      })

      axios.post('https://vul-api.wikisecurity.net/vuldb/nvd/search', {keyword: this.search})
      .then(response => {
        // console.log(response.data);
        this.result.nvd = response.data;
      })
      .catch(error => {
        console.log(error);
        this.searching.nvd = 'red';
      })
      .finally( ()=> {
        this.searching.nvd = false;
      })

      axios.post('https://vul-api.wikisecurity.net/vuldb/packetstorm/search', {keyword: this.search})
      .then(response => {
        // console.log(response.data);
        this.result.packetStorm = response.data;
      })
      .catch(error => {
        console.log(error);
        this.searching.packetStorm = 'red';
      })
      .finally( ()=> {
        this.searching.packetStorm = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
